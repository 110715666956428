import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "layout"
import Container from "layout/Container"

import useProofOfPaymentIcon from "../ProofOfPayment/hooks/useProofOfPaymentIcon"
import styles from "./utils/survey.module.scss"

const SurveyComplete = (props) => {
  const data = useProofOfPaymentIcon()
  return (
    <Layout title="Thank you!" seoTitle="Thank you">
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          <div className="column is-4-desktop is-12-mobile">
            <figure className={classNames("image mx-3")}>
              <Img
                fluid={data.email.childImageSharp.fluid}
                alt={`Survey Complete`}
                className={styles["image"]}
              />
            </figure>
          </div>
          <div className="column">
            <Fragment>
              <p className="mb-1">
                Thank you for answering our survey.
              </p>
              <p className="mb-1">
                Your feedback is incredibly valuable to us as it helps us continuously improve our services and better meet your needs.
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Link to="/" className="mt-2 button is-primary is-medium">
            Finish
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default SurveyComplete
